<template>
  <div class="d-flex flex-column overflow-y-hidden full-height">
    <v-tabs
      class="flex-grow-0"
      :background-color="themeController.bannerColor"
      color="unset"
      fixed-tabs
      height=42
      slider-color="primary"
      v-model="tabs"
    >
      <v-tab>{{$t('t.Summary')}}</v-tab>
      <v-tab>{{$t('t.Documents')}}</v-tab>
    </v-tabs>
    <keep-alive>
      <v-tabs-items
        class="transparent"
        v-model="tabs"
      >
        <v-tab-item>
          <div class="d-flex flex-column full-height overflow-y-auto">
            <aging-summary
              class="mt-6"
              :account="account"
            />
            <escalation-summary
              class="mt-4"
              :account="account"
              :accountId="$route.params.id"
            />
            <balance-summary
              class="mt-4"
              :account="account"
            />
            <sales-payments-summary
              class="mt-4"
              :account="account"
            />
            <promises-summary
              class="mt-4"
              v-if="account"
              :promisesData="account.promises"
            />
            <kpi
              class="mt-4"
              :account="account"
            />
          </div>
        </v-tab-item>
        <v-tab-item>
          <search
            class="pt-3"
            selected-document-type='invoices'
            :included-document-types="documentTypes"
            :scope="scope"
          />
        </v-tab-item>
      </v-tabs-items>
    </keep-alive>
  </div>
</template>

<script>
import themeController from '@/themeController'
import Search from '@/pages/search/controllers'

export default {
  name: 'account',
  components: {
    AgingSummary: () => import('@/pages/account/summary/aging/aging-summary'),
    BalanceSummary: () => import('@/pages/account/summary/balance/balance-summary'),
    EscalationSummary: () => import('@/pages/account/summary/escalation-summary'),
    Kpi: () => import('@/pages/account/summary/kpi/kpi'),
    PromisesSummary: () => import('@/components/kpi/promises-summary'),
    SalesPaymentsSummary: () => import('@/pages/account/summary/sales-payments-summary'),
    Search: () => import('@/pages/mobility/components/search')
  },
  activated () {
    this.loadAccount()
  },
  computed: {
    scope () {
      return Search.typeOfScope.account(this.$route.params.id)
    },
    themeController () { return themeController },
    documentTypes () { return ['invoices'] }
  },
  created () {
    this.loadAccount()
  },
  data () {
    return {
      account: undefined,
      search: new Search(Search.actions.GRID),
      tabs: 0
    }
  },
  methods: {
    async loadAccount () {
      const accountId = await this.$waitFor(() => this.$route.params.id)
      const { data } = await this.$http().get(`/core/v6/accounts/${accountId}/summary`)

      this.account = Object.assign(data, { id: this.$route.params.id })
      this.setModule()
    },
    setModule () {
      let moduleData = { name: this.$t('t.Account') }

      if (this.account) {
        moduleData = {
          componentName: 'account-header',
          data: this.account
        }
      }

      this.$store.commit('setModule', moduleData)
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-btn:not([value])
  display none

.full-height
  height 100%

>>> .v-tab:not(.v-tab--active)
  border-bottom-width 1px
  border-bottom-style solid

>>>
  .v-window__container, .v-window-item
    height 100%

@media only screen and (max-width 959px)
  >>> .v-stepper:not(.v-stepper--vertical) .v-stepper__label
    display flex !important

@media only screen and (max-width 959px)
  >>> .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step
    margin-right 8px !important
</style>
